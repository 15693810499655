// .MuiAppBar-root.MuiAppBar-colorDefault {
//     background-color: #fff;
// }
// .navMenu {
//     display: flex;

//     li {
//         position: relative;
//         width: auto;
//         padding: 0;
//         a {
//             font-size: .875rem;
//             font-weight: 500;
//             svg {
//                 font-size: 1rem;
//             }
//             &.active {
//                 color: #17939b;
//             }
//         }
//         &:hover {
//             .subNavMenu {
//                 visibility: visible;
//                 opacity: 1;
//                 height: auto;
//             }
//         }
//         .subNavMenu {
//             position: absolute;
//             top: 100%;
//             background: #fff;
//             transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//             border-radius: 4px;
//             box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
//             visibility: hidden;
//             opacity: 0;
//             height: 0;
//             transition: .5s;
//             margin: 0;
//             padding: 0;
//             li {
//                 a {
//                     white-space: nowrap;
//                     &.active {
//                         color: #00525a;
//                         background-color: #17939b;
//                     }
//                 }
//             }
//         }
//     }
// }

.navMenu {
  margin: 0 !important;
  padding: 0 !important;
  width: 80px;
  z-index: 0;
  background-color: #000000;

  > li {
    width: 80px;
    height: 80px;
    padding: 0;
    flex-direction: column;

    > a {
      flex-direction: column;
      font-size: 12px;
      padding: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #b5bfc7;

      svg {
        fill: #b5bfc7;
      }
    }

    &.active {
      > a {
        color: #cda778;

        svg {
          fill: #cda778;
        }
      }

      .subNavMenu {
        left: 80px;
        transform: translateX(0%);

        li {
          a {
            color: #b5bfc7;

            svg {
              fill: #b5bfc7;
            }

            &.active {
              color: #cda778;
              background: #000;
              svg {
                fill: #cda778;
              }
            }
          }
        }
      }
    }
  }

  .subNavMenu {
    z-index: -1;
    position: fixed;
    left: -80px;
    transform: translateX(-100%);
    background: #1d1d1d;
    padding: 10px !important;
    transition: 0.5s;

    > li {
      padding: 0;

      > a {
        font-size: 13px;
      }
    }
  }
}
