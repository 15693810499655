body {
    background-color: #f5f6f6!important;
}
.quotedPara {
    font-size: 18px;
    line-height: 2;
    opacity: .75;
    text-align: justify;
    position: relative;

    &::first-letter {
        font-size: 300%;
        color: #00525a;
        line-height: 1;
        opacity: 1;
    }

    svg {
        position: absolute;
        font-size: 1000%;
        top: -25%;
        left: -5%;
        color: #00525a;
        opacity: 0.15;
    }
}

.overlayBG {
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .25);
        // z-index: -1;
    }
}