.bannerSection {
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.25);
        z-index: -1;
    }

    &.homeBanner {
        min-height: 100dvh;
    }

    &.subBanner {
        min-height: 50dvh;
    }
}